import React, {ReactElement, useEffect, useState} from "react";
import {
    AuctionManager,
    Bid,
    BidEvent,
    DeleteBidEvent,
    Item,
    OpenItemEvent
} from "./utils/auction";
import {CurrentItem} from "./currentitem";
import {BidList} from "./bids";
import {ItemList} from "./items";
import {Stats} from "./stats";
import {AppBar, Toolbar} from "@mui/material";

export interface AuctionAppProps {
    auction: AuctionManager;
}

export function AuctionApp(props: AuctionAppProps): ReactElement {
    const [currentItem, setCurrentItem] = useState(props.auction.getCurrentItem() || undefined);
    const [currentBids, setCurrentBids] = useState([] as Bid[]);
    const [items, setItems] = useState([] as Item[]);

    useEffect(() => {
        const updateCurrentItem = (e: Event) => {
            if (!(e instanceof OpenItemEvent)) {
                return;
            }
            setCurrentItem(e.currentItem || undefined);
            setItems(props.auction.getItems());
        }

        const updateItems = (e: Event) => {
            setItems(props.auction.getItems());
        }

        const closeItem = (e: Event) => {
            setCurrentItem(undefined);
            setItems(props.auction.getItems());
        }

        props.auction.addEventListener('openitem', updateCurrentItem);
        props.auction.addEventListener('createitem', updateItems);
        props.auction.addEventListener('deleteitem', updateItems);
        props.auction.addEventListener('updateitem', updateItems);
        props.auction.addEventListener('closeitem', closeItem);
        props.auction.addEventListener('refresh', updateItems);
        setItems(props.auction.getItems());

        return () => {
            props.auction.removeEventListener('openitem', updateCurrentItem);
            props.auction.removeEventListener('createitem', updateItems);
            props.auction.removeEventListener('deleteitem', updateItems);
            props.auction.removeEventListener('updateitem', updateItems);
            props.auction.removeEventListener('closeitem', closeItem);
            props.auction.removeEventListener('refresh', updateItems);
        }
    }, [props.auction]);

/*    async function refreshItems(): Promise<void> {
        if(currentItem !== undefined) {
            setCurrentBids(await props.auction.getItemBids(currentItem.id));
            setCurrentItem(await props.auction.getCurrentItem() || undefined);
            setItems(await props.auction.getItems());
        }
    }*/

    useEffect(() => {
        if (!currentItem) {
            setCurrentBids([]);
            return;
        }

        (async () => {
            if(currentItem.id !== undefined) {
                setCurrentBids(await props.auction.getItemBids(currentItem.id));
            }
        })();

    }, [props.auction, currentItem]);


    useEffect(() => {
        const bidCreated = (e: Event) => {
            if (!(e instanceof BidEvent)) {
                return;
            }
            setCurrentBids(currentBids.concat(e.bid));
        }

        const bidDeleted = (e: Event) => {
            if (!(e instanceof DeleteBidEvent)) {
                return;
            }
            console.log(e, e.bidId, currentBids);
            setCurrentBids(currentBids.filter(x => x.id !== e.bidId));
        }

        props.auction.addEventListener('bid', bidCreated);
        props.auction.addEventListener('deletebid', bidDeleted);
        props.auction.addEventListener('refresh', updateBids);

        return () => {
            props.auction.removeEventListener('bid', bidCreated);
            props.auction.removeEventListener('deletebid', bidDeleted);
            props.auction.removeEventListener('refresh', updateBids);
        }

        async function updateBids(): Promise<void> {
            if(currentItem !== undefined) {
                setCurrentBids(await props.auction.getItemBids(currentItem.id));
            }
        }

    }, [props.auction, currentBids, currentItem]);


    return (
        <>
        <AppBar position="static">
            <Toolbar>
                Ponyfest Auction Controller - Work in progress
            </Toolbar>
        </AppBar>
        <div className="AuctionApp">
            <ItemList items={items} currentItem={currentItem} auction={props.auction} />
            <CurrentItem item={currentItem} auction={props.auction} />
            <BidList bids={currentBids} auction={props.auction} />
            <Stats auction={props.auction} />
        </div>
        </>
    )
}