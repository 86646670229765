import process from "process";

export const AUCTION_SERVICE_URL: string = process.env["REACT_APP_AUCTION_SERVICE_URL"] ?? "https://auction-api.ponyfest.horse";
export const AUCTION_OVERLAY_URL: string = process.env["REACT_APP_AUCTION_OVERLAY_URL"] ?? "https://auction-overlay.ponyfest.horse";

export const AUCTION_OVERLAY_OBS_URL  = AUCTION_OVERLAY_URL + "/?password=";
export const AUCTION_OVERLAY_OBS_BIG_WEBCAM_URL = AUCTION_OVERLAY_URL +"/?webcam=big&password=";

interface ReceivedCloseItemEvent {
    type: 'closeItem';
    event: {
        itemId: string;
    }
}

interface ReceivedUpdateItemEvent {
    type: 'updateItem';
    event: {
        itemId: string;
    }
}

interface ReceivedOpenItemEvent {
    type: 'openItem';
    event: {
        itemId: string;
    }
}

interface ReceivedCreateItemEvent {
    type: 'createItem';
    event: {
        itemId: string;
    }
}

interface ReceivedDeleteItemEvent {
    type: 'deleteItem';
    event: {
        itemId: string;
    }
}

interface ReceivedDeleteBidEvent {
    type: 'deleteBid';
    event: {
        itemId: string;
        bidId: string;
    }
}

interface ReceivedBidEvent {
    type: 'bid';
    event: Bid;
}

interface ReceievedSystemUpdateEvent {
    type: 'systemUpdate';
    event: {
        updateType: string;
    }
}

type Event = ReceivedCloseItemEvent | ReceivedOpenItemEvent | ReceivedUpdateItemEvent | ReceivedCreateItemEvent | ReceivedDeleteItemEvent | ReceivedDeleteBidEvent | ReceivedBidEvent | ReceievedSystemUpdateEvent;

export interface Item {
    id: string;
    createdTime?: string;
    updatedTime?: string;
    eventID?: string;
    name: string;
    description: string;
    images?: string[];
    startBid: number;
    status?: number;
    donor: string;
    country: string;
    finalBid?: number;
    winner?: string;
    winnerID?: string;
}

export interface Bid {
    bid: number;
    bidder: string;
    bidderDisplayName: string;
    id: string;
    itemId: string;
}

export interface Totals {
    status: string;
    totalCents: number;
}

export interface MaxBids {
    status: string;
    maxBid: number;
}

export const ItemStatus = {
    ItemStatusPending: 0,
    ItemStatusApproved: 1,
    ItemStatusRejected: 2,
    ItemStatusOpened: 3,
    ItemStatusClosed: 4
}

export class ReadyEvent extends Event {
    constructor(eventInit?: EventInit) {
        super("ready", eventInit);
    }
}

export class OpenItemEvent extends Event {
    public readonly currentItem: Item;

    constructor(eventInit: EventInit & {item: Item}) {
        super("openitem", eventInit);
        this.currentItem = eventInit.item;
    }
}

export class UpdateItemEvent extends Event {
    public readonly itemId: string;

    constructor(eventInit: EventInit & {itemId: string}) {
        super("updateitem", eventInit);
        this.itemId = eventInit.itemId;
    }
}

export class CreateItemEvent extends Event {
    public readonly itemId: string;

    constructor(eventInit: EventInit & {itemId: string}) {
        super("createitem", eventInit);
        this.itemId = eventInit.itemId;
    }
}


export class DeleteItemEvent extends Event {

    constructor(eventInit: EventInit & {}) {
        super("deleteitem", eventInit);
    }
}


export class CloseItemEvent extends Event {
    public readonly itemId: string;

    constructor(eventInit: EventInit & {itemId: string}) {
        super("closeitem", eventInit);

        this.itemId = eventInit.itemId;
    }
}

export class BidEvent extends Event {
    public readonly bid: Bid;

    constructor(eventInit: EventInit & {bid: Bid}) {
        super("bid", eventInit);
        this.bid = eventInit.bid;
    }
}

export class DeleteBidEvent extends Event {
    public readonly itemId: string;
    public readonly bidId: string;

    constructor(eventInit: EventInit & {bidId: string, itemId: string}) {
        super("deletebid", eventInit);
        this.itemId = eventInit.itemId;
        this.bidId = eventInit.bidId;
    }
}

export class SystemUpdateEvent extends Event {
    public readonly updateType: string;

    constructor(eventInit: EventInit & {updateType: string}) {
        super("systemupdate", eventInit);
        this.updateType = eventInit.updateType;
    }
}

export class RefreshEvent extends Event {

    constructor(eventInit: EventInit & {}) {
        super("refresh", eventInit);
    }
}

export class AuctionManager extends EventTarget {
    private events: EventSource;
    private items: Item[] = [];
    private currentItem: Item | null = null;
    private ready = false;

    constructor(private password: string) {
        super();
        this.events = new EventSource(`${AUCTION_SERVICE_URL}/api/events?password=${encodeURIComponent(password)}`);
        this.events.onmessage = (e) => this.handleMessage(e.data);
        this.fetchItems().finally();
    }

    public getItem(id: string): Item | undefined {
        return this.items.filter(x => x.id === id)[0];
    }

    public getCurrentItem(): Item | null {
        return this.currentItem;
    }

    public getItems(): Item[] {
        return this.items;
    }

    public async createItem(item: Item): Promise<void> {
        const json: string = encodeURI(JSON.stringify(item)).replaceAll("&","%26");
        await this.fetch(`${AUCTION_SERVICE_URL}/api/items`, {method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded'}, body: `data=${json}`})
    }

    public async updateItem(item: Item): Promise<void> {
        const json: string = encodeURI(JSON.stringify(item)).replaceAll("&","%26");
        await this.fetch(`${AUCTION_SERVICE_URL}/api/items`, {method: 'PUT', headers: {'Content-Type': 'application/x-www-form-urlencoded'}, body: `data=${json}`})
    }

    public async deleteItem(item: Item): Promise<void> {
        await this.fetch(`${AUCTION_SERVICE_URL}/api/items/${item.id}`, {method: 'DELETE'});
    }

    public async updateItemStatus(item: Item, status: number): Promise<void> {
        await this.fetch(`${AUCTION_SERVICE_URL}/api/items/${item.id}/status`, {method: 'PUT', headers: {'Content-Type': 'application/x-www-form-urlencoded'}, body: `status=${status}`})

    }

    public async getItemBids(itemId: string): Promise<Bid[]> {
        const response = await this.fetch(`${AUCTION_SERVICE_URL}/api/items/${itemId}/bids`);
        const json = await response.json();
        return json.bids;
    }

    public async getItemInfo(itemId: string): Promise<Item> {
        const request =  await this.fetch(`${AUCTION_SERVICE_URL}/api/items/${itemId}`)
        const json = await request.json();
        return json.item
    }

    public async openItem(itemId: string): Promise<void> {
        await this.fetch(`${AUCTION_SERVICE_URL}/api/bidding`, {method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded'}, body: `itemId=${itemId}`})
    }

    public async closeItem(): Promise<void> {
        await this.fetch(`${AUCTION_SERVICE_URL}/api/bidding`, {method: 'DELETE'});
    }

    public async deleteBid(itemId: string, bidId: string): Promise<void> {
        await this.fetch(`${AUCTION_SERVICE_URL}/api/items/${itemId}/bids/${bidId}`, {method: "DELETE"});
    }

    public async deleteAllBid(itemId: string): Promise<void> {
        await this.fetch(`${AUCTION_SERVICE_URL}/api/items/${itemId}/bids/`, {method: "DELETE"});
    }

    public async getTotalCents(): Promise<number> {
        const response = await this.fetch(`${AUCTION_SERVICE_URL}/api/total`);
        const json : Totals = await response.json();
        return json.totalCents;
    }

    public async getMaxBidLimit(): Promise<number> {
        const response = await this.fetch(`${AUCTION_SERVICE_URL}/api/maxbidding`);
        const json : MaxBids = await response.json();
        return json.maxBid;
    }

    // async fetchItemUpdate(itemId: string) {
    //     let newItem = await this.getItemInfo(itemId)
    //     let itemIndex = this.items.findIndex(x => (x.id === itemId))
    //     this.items[itemIndex] = newItem
    // }
    async fetchItems() {
        let request = await this.fetch(`${AUCTION_SERVICE_URL}/api/items`);
        let json = await request.json();
        this.items = json.items;
        request = await this.fetch(`${AUCTION_SERVICE_URL}/api/bidding`, {method: "GET"});
        json = await request.json();
        this.currentItem = json.item;
        this.ready = true
        this.dispatchEvent(new ReadyEvent());
    }

    private handleMessage(data: string) {
        const json = JSON.parse(data) as Event;
        console.log(json);
        switch (json.type) {
            case "openItem":
                let item = this.getItem(json.event.itemId);
                if (!item) {
                    alert("The current item does not exist. Try reloading the page?");
                    return;
                }
                this.currentItem = item;
                this.dispatchEvent(new OpenItemEvent({item}));
                break;
            case "closeItem":
                this.currentItem = null;
                if (this.getItem(json.event.itemId)) {
                    this.getItem(json.event.itemId)!.status = ItemStatus.ItemStatusClosed;
                }
                this.dispatchEvent(new CloseItemEvent({itemId: json.event.itemId}));
                break;
            case "createItem":
                this.fetchItems().then(_ =>
                    this.dispatchEvent(new CreateItemEvent({itemId: json.event.itemId}))
                )
                break;
            case "updateItem":
                this.fetchItems().then(_ =>
                    this.dispatchEvent(new UpdateItemEvent({itemId: json.event.itemId}))
                )
                break;
            case "deleteItem":
                this.fetchItems().then(_ =>
                    this.dispatchEvent(new DeleteItemEvent({}))
                )
                break;
            case "bid":
                this.dispatchEvent(new BidEvent({bid: json.event}))
                break;
            case "deleteBid":
                this.dispatchEvent(new DeleteBidEvent(json.event));
                break;
            case "systemUpdate":
                this.dispatchEvent(new SystemUpdateEvent(json.event))
                break;
        }
    }

    private fetch(url: string, init?: RequestInit): Promise<Response> {
        if (url.indexOf('?') !== -1) {
            url += '&password=' + this.password;
        }  else {
            url += '?password=' + this.password;
        }
        return fetch(url, init);
    }
}