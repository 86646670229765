import React, {ReactElement} from "react";
import {AuctionManager, Bid} from "./utils/auction";
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem
} from "@mui/material";
import Delete from '@mui/icons-material/Delete';
import {reverse} from "./utils/reverse";

export interface BidListProps {
    bids: Bid[];
    auction: AuctionManager;
}

export function BidList(props: BidListProps): ReactElement {
    return (
        <Card style={{width: 400, margin: 20}}>
            <CardHeader title="Current Bids" />
            <CardContent>
                <List>
                    {Array.from(reverse(props.bids).entries()).slice(0, 10).map(([i, x]) => (
                        <MenuItem  selected={i % 2 === 0} key={x.id}>
                            <ListItemText primary={`${x.bidderDisplayName} — $${(x.bid / 100).toFixed(2)}`} />
                            <ListItemSecondaryAction>
                                <IconButton title={"delete bid"} onClick={() => props.auction.deleteBid(x.itemId, x.id)} size="large"><Delete /></IconButton>
                            </ListItemSecondaryAction>
                        </MenuItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
}