import React, {ReactElement, useEffect, useState} from "react";
import {AuctionManager, Item} from "./utils/auction";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    TextField
} from "@mui/material";

export interface CreateItemProps {
    item?: Item;
    auction: AuctionManager;
    open: boolean;
    newItem: boolean;
    onClose: () => void
}

export const defaultItem: Item = {
    id: "",
    name: "Default Name",
    description: "Description",
    images: ["https://files.cults3d.com/uploaders/17356829/illustration-file/dabc7014-7e2f-4558-b5fe-7e7a27ac0036/sylveon.jpg","https://media.entertainmentearth.com/assets/images/ba9a1f466e8c4d8fa976258a7eafb944xl.jpg"],
    startBid: 100,
    finalBid: 0,
    donor: "Anon",
    country: "Internet"
}

export function CreateItem(props: CreateItemProps): ReactElement {
    const [createItemTitle, setCreateItemTitle] = useState("")
    const [initialOpen, setInitialOpen] = useState(true)
    const [fieldError, setFieldError] = useState(false)

    // User form input
    const [itemName, setItemName] = useState("")
    const [itemDescription, setItemDescription] = useState("")
    const [itemImages, setItemImages] = useState("")
    const [startBid, setStartBidValue] = useState("")
    const [itemDonor, setItemDonor] = useState("")
    const [country, setCountry] = useState("")
    const [item, setItem] = useState(defaultItem)


    useEffect(() => {
        if (!props.open) {
            if (!initialOpen) {
                setInitialOpen(true)
            }
            return;
        }

        if (initialOpen) {
            setInitialOpen(false)
            console.log("Opening Item Manager")

            // Check if we are creating or updating an item
            if(props.newItem) {
                setCreateItemTitle("Create Item:")
            } else {
                setCreateItemTitle("Edit Item:")
                // Load item if we provide it from above
                if (props.item === undefined) {
                    console.log("Missing item to edit")
                    props.onClose()
                } else {
                    setItem(props.item)
                }
            }
        }
    }, [props, initialOpen]);

    useEffect(() => {
        // Populate the entry fields
        setItemName(item.name)
        setItemDescription(item.description)
        setStartBidValue((item.startBid / 100).toString(10))
        setItemDonor(item.donor)
        setCountry(item.country)

        if (item.images !== undefined) {
            setItemImages(item.images.join(","))
        }
    }, [item]);

/*    async function handleCreateItem(item: Item): Promise<void> {
        await props.auction.createItem(item);
    }*/

/*    const loadItem = (item: Item) => {
        if (item === undefined) {
            // Populate the item interface with defaults
            props.item = defaultItem;
        } else {
            props.item = item;
        }
    }*/

    const onSubmit = (event: any) => {
        event.preventDefault()

        if (itemDonor === '' || country === '' || itemName === '' || startBid === '0' || itemDescription === '' || itemImages.length === 0) {
            setFieldError(true)
        } else {
            item.donor = itemDonor
            item.country = country
            item.name = itemName
            item.startBid = parseBid(startBid)
            item.description = itemDescription
            item.images = itemImages.split(",")
        }

        if (fieldError) {
            console.error("missing fields")
            setFieldError(false)
        } else {
            if(props.newItem) {
                console.info(`Creating new item ${JSON.stringify(item)}`)
                props.auction.createItem(item).then(props.onClose)
            } else {
                console.info(`Updating existing item ${JSON.stringify(item)}`)
                props.auction.updateItem(item).then(props.onClose)
            }
        }
    }

    const parseBid = (value: string) => {
        const num = (parseFloat(value)* 100).toFixed(0)
        return parseInt(num)
    }

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>
                    <span style={{float: "left", paddingTop: 9}}>{createItemTitle}</span>
                </DialogTitle>
                    <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }} noValidate autoComplete="off">
                        <div>
                            <TextField
                                required
                                value={itemDonor}
                                onChange={ e=>setItemDonor(e.target.value)}
                                id="item-donor"
                                label="Donator:"
                                variant="filled"/>
                            <TextField
                                required
                                value={country}
                                onChange={ e=>setCountry(e.target.value)}
                                id="item-country"
                                label="Country:"
                                variant="filled"
                            />
                        </div>
                        <div>
                            <TextField
                                required
                                value={itemName}
                                onChange={ e=>setItemName(e.target.value)}
                                id="item-name"
                                label="Item Name:"
                                variant="filled"
                            />
                            <TextField
                                required
                                type="number"
                                value={startBid}
                                onChange={ e=>setStartBidValue(e.target.value)}
                                id="item-startBid"
                                label="Starting Bid:"
                                variant="filled"
                            />
                        </div>
                    </Box>
                    <Box sx={{ m: 1, width: '52ch' }}>
                        <div>
                            <TextField
                                sx={{ m: 1, width: '50ch' }}
                                required
                                value={itemDescription}
                                onChange={ e=>setItemDescription((e.target.value))}
                                id="item-description"
                                label="Description"
                                variant="filled"
                                multiline
                            />
                        </div>
                        <div>
                            <TextField
                                sx={{ m: 1, width: '50ch' }}
                                required
                                value={itemImages}
                                onChange={ e=>setItemImages(e.target.value)}
                                id="item-images"
                                label="Images Links:"
                                multiline
                                variant="filled"
                            />
                        </div>
                        <Button sx={{ m: 1}} variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                    </Box>

            </Dialog>
        </>
    )
}