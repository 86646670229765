import React, {ReactElement} from "react";
import {AuctionManager, Item} from "./utils/auction";
import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    ImageList,
    ImageListItem,
    ListItem,
    Typography
} from "@mui/material";

export interface CurrentItemProps {
    item?: Item;
    auction: AuctionManager
}

export function CurrentItem(props: CurrentItemProps): ReactElement {
    let itemDetails: React.JSX.Element
    if (props.item) {
        let startBid = ""
        if (props.item.startBid !== undefined) {
            startBid = (props.item.startBid / 100).toFixed(2)
        }
        itemDetails = (
            <>
                <CardContent sx={{paddingTop: 2, paddingBottom: 7 }}>
                    <span style={{float: "left"}}>
                        <Typography variant="h5" component="h5">
                            Auction item
                        </Typography>
                    </span>

                    <span style={{float: "right"}}>
                        <Button variant="contained" color="primary" onClick={() => props.auction.closeItem()}>Close item</Button>
                    </span>
                </CardContent>
                <Divider />
                <CardContent>
                    <Typography variant="h4" component="h4">
                        {props.item.name}
                    </Typography>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                <Typography>
                                    Starting bid: ${startBid}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                <Typography>
                                    Description: {props.item.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <ImageList rowHeight='auto' cols={1}>
                            {(props.item.images || []).map(x => <ImageListItem key={x}><img src={x} alt="" /></ImageListItem>)}
                        </ImageList>
                    </ListItem>
                </CardContent>
            </>
        );
    } else {
        itemDetails = (
            <>
                <CardContent sx={{paddingTop: 2, paddingBottom: 7 }}>
                    <span style={{float: "left"}}>
                        <Typography variant="h5" component="h5">
                            No auction item
                        </Typography>
                    </span>

                    <span style={{float: "right"}}>
                        <Button variant="contained" disabled >Close item</Button>
                    </span>
                </CardContent>
                <Divider />
            </>
        );
    }
    return (
        <Card style={{width: 400, margin: 20}}>
            {itemDetails}
        </Card>
    )
}