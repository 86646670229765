import React, {ReactElement, useState} from "react";
import {AuctionManager, Item, ItemStatus} from "./utils/auction";
import {
    Button,
    Card,
    CardContent,
    Collapse, Divider,
    List,
    ListItemButton,
    ListItemText,
    Grid,
    Stack,
    Snackbar, ListItem, ImageListItem, ImageList, Typography
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {CreateItem, defaultItem} from "./createitem";

export interface ItemListProps {
    items: Item[];
    currentItem?: Item;
    auction: AuctionManager;
}

export function ItemList(props: ItemListProps): ReactElement {
    const [warningMessage, setWarningMessage] = useState("");

    const [open, setOpen] = React.useState([0]);
    const [showingItemPrompt, setShowingItemPrompt] = useState(false);
    const [showingItemEditPrompt, setShowingItemEditPrompt] = useState(false);
    const [editItem, setEditItem] = useState(defaultItem);

    const handleToggle = (value: number) => () => {
        const currentIndex = open.indexOf(value);
        const newOpened = [...open];

        if (currentIndex === -1) {
            newOpened.push(value);
        } else {
            newOpened.splice(currentIndex, 1);
        }

        setOpen(newOpened);
    };

    async function openItem(item: Item): Promise<void> {
        if(item.id === undefined) {
            return;
        }
        if (props.currentItem) {
            setWarningMessage("You already have an item open for bidding, so cannot open another.");
            return;
        }
        if (item.status === ItemStatus.ItemStatusClosed && !window.confirm(`Are you sure you want to re-open a previously auctioned item (${item.name}) for bidding?`)) {
            return;
        }
        await props.auction.openItem(item.id);
    }

    async function approveItem(item: Item, status: number): Promise<void> {
        await props.auction.updateItemStatus(item, status);
    }

    async function deleteItem(item: Item): Promise<void> {
        await props.auction.deleteItem(item);
    }

    function showItemPrompt() {
        setShowingItemPrompt(true);
    }

    function showItemEditPrompt(item: Item) {
        setEditItem(item)
        setShowingItemEditPrompt(true);
    }

    function generateListItemPending(item: Item): ReactElement {
        let startBid = ""
        if (item.startBid !== undefined) {
            startBid = (item.startBid / 100).toFixed(2)
        }

        return (
            <>
            <ListItemButton key={item.id} onClick={handleToggle(parseInt(item.id.replace(/-/g, ''), 16))}>
                <ListItemText primary={item.name} secondary={`${item.donor} — ${item.country}`} />
                {open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1} timeout="auto" unmountOnExit>
                <List component="div">
                    <ListItem>
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" color="primary" onClick={() => showItemEditPrompt(item)}>Edit</Button>
                            <Button variant="contained" color="primary" onClick={() => approveItem(item,ItemStatus.ItemStatusApproved)}>Approve</Button>
                            <Button variant="contained" color="secondary" onClick={() => approveItem(item,ItemStatus.ItemStatusRejected)}>Reject</Button>
                        </Stack>
                    </ListItem>
                    <ListItem>
                        <ImageList rowHeight='auto' cols={1}>
                            {(item.images || []).map(x => <ImageListItem key={x}><img src={x} alt="" /></ImageListItem>)}
                        </ImageList>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                <Typography>
                                    Starting bid: ${startBid}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                <Typography>
                                    Description: {item.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </Collapse>
            </>
        )
    }

    function generateListItemApproved(item: Item): ReactElement {
        let startBid = ""
        if (item.startBid !== undefined) {
            startBid = (item.startBid / 100).toFixed(2)
        }
        return (
            <>
                <ListItemButton key={item.id} onClick={handleToggle(parseInt(item.id.replace(/-/g, ''), 16))}>
                    <ListItemText primary={item.name} secondary={`${item.donor} — ${item.country}`} />
                    {open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem>
                            <Stack spacing={2} direction="row">
                            <Button variant="contained" color="primary" onClick={() => props.auction.updateItemStatus(item,ItemStatus.ItemStatusPending)}>Send to Edit</Button>
                            <Button variant="contained" color="primary" onClick={() => openItem(item)}>Start Bidding</Button>
                            </Stack>
                        </ListItem>
                        <ListItem>
                            <ImageList rowHeight='auto' cols={1}>
                                {(item.images || []).map(x => <ImageListItem key={x}><img src={x} alt="" /></ImageListItem>)}
                            </ImageList>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Starting bid: ${startBid}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Description: {item.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Collapse>
            </>
        )
    }

    function generateListItemOpen(item: Item): ReactElement {
        let startBid = ""
        if (item.startBid !== undefined) {
            startBid = (item.startBid / 100).toFixed(2)
        }
        return (
            <>
                <ListItemButton key={item.id} onClick={handleToggle(parseInt(item.id.replace(/-/g, ''), 16))}>
                    <ListItemText primary={item.name} secondary={`${item.donor} — ${item.country}`} />
                    {open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem>
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="primary" onClick={() => props.auction.updateItemStatus(item,ItemStatus.ItemStatusApproved)}>Send to Approve</Button>
                                <Button variant="contained" color="secondary" onClick={() => props.auction.updateItemStatus(item,ItemStatus.ItemStatusClosed)}>Close Bidding</Button>
                            </Stack>
                        </ListItem>
                        <ListItem>
                            <ImageList rowHeight='auto' cols={1}>
                                {(item.images || []).map(x => <ImageListItem key={x}><img src={x} alt="" /></ImageListItem>)}
                            </ImageList>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Starting bid: ${startBid}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Description: {item.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Collapse>
            </>
        )
    }

    function generateListItemRejected(item: Item): ReactElement {
        let startBid = ""
        if (item.startBid !== undefined) {
            startBid = (item.startBid / 100).toFixed(2)
        }
        return (
            <>
                <ListItemButton key={item.id} onClick={handleToggle(parseInt(item.id.replace(/-/g, ''), 16))}>
                    <ListItemText primary={item.name} secondary={`${item.donor} — ${item.country}`} />
                    {open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem>
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="primary" onClick={() => approveItem(item,ItemStatus.ItemStatusPending)}>Send to Pending</Button>
                                <Button variant="contained" color="secondary" onClick={() => deleteItem(item)}>Delete</Button>
                            </Stack>
                        </ListItem>
                        <ListItem>
                            <ImageList rowHeight='auto' cols={1}>
                                {(item.images || []).map(x => <ImageListItem key={x}><img src={x} alt="" /></ImageListItem>)}
                            </ImageList>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Starting bid: ${startBid}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Description: {item.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Collapse>
            </>
        )
    }

    function generateListItemClosed(item: Item): ReactElement {
        let startBid = ""
        if (item.startBid !== undefined) {
            startBid = (item.startBid / 100).toFixed(2)
        }

        let finalBid = ""
        if (item.finalBid !== undefined) {
            finalBid = (item.finalBid / 100).toFixed(2)
        }

        return (
            <>
                <ListItemButton key={item.id} onClick={handleToggle(parseInt(item.id.replace(/-/g, ''), 16))}>
                    <ListItemText primary={item.name} secondary={`${item.donor} — ${item.country}`} />
                    {open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.indexOf(parseInt(item.id.replace(/-/g, ''), 16)) !== -1} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem>
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="primary" onClick={() => openItem(item)}>Reopen Bidding</Button>
                            </Stack>
                        </ListItem>
                        <ListItem>
                            <ImageList rowHeight='auto' cols={1}>
                                {(item.images || []).map(x => <ImageListItem key={x}><img src={x} alt="" /></ImageListItem>)}
                            </ImageList>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Starting bid: ${startBid}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Final bid: ${finalBid}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Winner: {item.winner}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        WinnerID: {item.winnerID}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        Description: {item.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Collapse>
            </>
        )
    }

    const pendingItems = props.items.filter(x => (x.status === ItemStatus.ItemStatusPending));
    const pendingList = pendingItems.length === 0 ? <></> : <>
        <ListItemButton onClick={handleToggle(ItemStatus.ItemStatusPending)}>
            <Typography variant="h5" component="h6">
                Edit items
            </Typography>
        </ListItemButton>
            <Collapse in={open.indexOf(ItemStatus.ItemStatusPending) !== -1} timeout="auto" unmountOnExit>
                {pendingItems.map(generateListItemPending)}
            </Collapse>
        </>

    const approvedItems = props.items.filter(x => (x.status === ItemStatus.ItemStatusApproved ));
    const approvedList = approvedItems.length === 0 ? <></> : <>
        <ListItemButton onClick={handleToggle(ItemStatus.ItemStatusApproved)}>
            <Typography variant="h5" component="h6">
                Open items
            </Typography>
        </ListItemButton>
        <Collapse in={open.indexOf(ItemStatus.ItemStatusApproved) !== -1} timeout="auto" unmountOnExit>
            {approvedItems.map(generateListItemApproved)}
        </Collapse>
        </>

    const rejectedItems = props.items.filter(x =>  x.status === ItemStatus.ItemStatusRejected);
    const rejectedList = rejectedItems.length === 0 ? <></> : <>
        <ListItemButton onClick={handleToggle(ItemStatus.ItemStatusRejected)}>
            <Typography variant="h5" component="h6">
                Rejected items
            </Typography>
        </ListItemButton>
        <Collapse in={open.indexOf(ItemStatus.ItemStatusRejected) !== -1} timeout="auto" unmountOnExit>
            {rejectedItems.map(generateListItemRejected)}
        </Collapse>
    </>

    const currentItems = props.items.filter(x => (x.id !== props.currentItem?.id) && (x.status === ItemStatus.ItemStatusOpened) );
    const currentList = currentItems.length === 0 ? <></> : <>
        <ListItemButton onClick={handleToggle(ItemStatus.ItemStatusOpened)}>
            <Typography variant="h5" component="h6">
                Current item
            </Typography>
        </ListItemButton>
        <Collapse in={open.indexOf(ItemStatus.ItemStatusOpened) !== -1} timeout="auto" unmountOnExit>
            {currentItems.map(generateListItemOpen)}
        </Collapse>
    </>

    const closedItems = props.items.filter(x => (x.status === ItemStatus.ItemStatusClosed ));
    const closedList = closedItems.length === 0 ? <></> : <>
        <ListItemButton onClick={handleToggle(ItemStatus.ItemStatusClosed)}>
            <Typography variant="h5" component="h6">
                Closed items
            </Typography>
        </ListItemButton>
        <Collapse in={open.indexOf(ItemStatus.ItemStatusClosed) !== -1} timeout="auto" unmountOnExit>
            {closedItems.map(generateListItemClosed)}
        </Collapse>
    </>

    return (
        <>
            <Card style={{width: 400, height: "85vh", margin: 20, overflow: 'auto'}}>
                <CardContent sx={{paddingTop: 2, paddingBottom: 7 }}>
                    <span style={{float: "left"}}>
                        <Typography variant="h4" component="h4">
                            Items
                        </Typography>
                    </span>
                    <span style={{float: "right"}}>
                        <Button variant="contained" color="primary" onClick={() => showItemPrompt()}>Create Item</Button>
                    </span>
                </CardContent>
                <Divider />
                <CardContent title="Click to expand pending Items">
                    {pendingList}
                </CardContent>
                <Divider />
                <CardContent title="Click to expand approved Items">
                    {approvedList}
                </CardContent>
                <Divider />
                <CardContent title="Click to expand current Item">
                    {currentList}
                </CardContent>
                <Divider />
                <CardContent title="Click to expand closed Items">
                    {closedList}
                </CardContent>
                <Divider />
                <CardContent title="Click to expand rejected Items">
                    {rejectedList}
                </CardContent>
            </Card>
            <CreateItem auction={props.auction} open={showingItemPrompt} onClose={() => setShowingItemPrompt(false)} newItem={true}/>
            <CreateItem auction={props.auction} open={showingItemEditPrompt} onClose={() => setShowingItemEditPrompt(false)} newItem={false} item={editItem}/>
        <Snackbar message={warningMessage} open={warningMessage !== ""} autoHideDuration={3000} onClose={() => setWarningMessage("")} />
        </>
    )
}
