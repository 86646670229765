import React, {ReactElement, useEffect, useState} from "react";
import {Button, Card, CardContent, Divider, List, ListItem, Snackbar, Stack, Typography} from "@mui/material";
import {
    AuctionManager,
    RefreshEvent,
    AUCTION_OVERLAY_OBS_BIG_WEBCAM_URL, AUCTION_OVERLAY_OBS_URL
} from "./utils/auction";
import moment from "moment-timezone";
import { useCopyToClipboard } from 'usehooks-ts'

export interface StatsProps {
    auction: AuctionManager;
}

export function Stats(props: StatsProps): ReactElement {
    const [totalRaised, setTotalRaised] = useState(undefined as number | undefined);
    const [maxBidLimit, setMaxBidLimit] = useState(undefined as number | undefined);
    const [time, setTime] = useState(moment());
    const [, copy] = useCopyToClipboard()
    const [noticeMessage, setNoticeMessage] = useState("");

    useEffect(() => {
        async function updateTotal() {
            setTotalRaised(await props.auction.getTotalCents());
        }

        async function updateMaxBidLimit() {
            setMaxBidLimit(await props.auction.getMaxBidLimit());
        }

        props.auction.addEventListener('openitem', updateTotal);
        props.auction.addEventListener('closeitem', updateTotal);
        props.auction.addEventListener('systemupdate', updateMaxBidLimit);
        updateTotal().finally();
        updateMaxBidLimit().finally();

        return () => {
            props.auction.removeEventListener('openitem', updateTotal);
            props.auction.removeEventListener('closeitem,', updateTotal);
            props.auction.removeEventListener('systemupdate', updateMaxBidLimit);
        }
    }, [props.auction]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(moment());
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    })

    async function refreshItems(): Promise<void> {
        props.auction.fetchItems().then(_ => props.auction.dispatchEvent(new RefreshEvent({})))
        setNoticeMessage("Refresh Complete")
    }

    async function copyLink(Link: string): Promise<void> {
        await copy(Link);
        setNoticeMessage("Link copied");
    }

    return <>
        <Card style={{width: 400, margin: 20}}>
            <CardContent sx={{paddingTop: 2, paddingBottom: 2 }}>
                <Typography variant="h4" component="h4">
                    Stats
                </Typography>
                <List component="div">
                    <ListItem>
                        Current time: {time.tz("America/New_York").format("HH:mm:ss")} EDT
                    </ListItem>
                    <ListItem>
                        Total raised: {totalRaised === undefined ? 'loading': '$' + (totalRaised/100).toFixed(2)}
                    </ListItem>
                    <ListItem>
                        Max Bidding Limit: {maxBidLimit === undefined ? 'loading': '$' + (maxBidLimit/100).toFixed(2)}
                    </ListItem>
                </List>
            </CardContent>
            <Divider />
            <CardContent sx={{paddingTop: 2, paddingBottom: 2 }}>
                <Typography variant="h4" component="h4">
                    Utils
                </Typography>
                <List component="div">
                    <ListItem>
                        <Stack spacing={2} direction="column">
                            {/*<Button variant="contained" color="primary" onClick={() => props.auction.updateItemStatus(item,ItemStatus.ItemStatusApproved)}>Refresh Items</Button>*/}
                            <Button variant="contained" color="primary" onClick={() => {copyLink(AUCTION_OVERLAY_OBS_URL + localStorage["auctionPassword"]).finally()}}>OBS Overlay [Item]</Button>
                            <Button variant="contained" color="primary" onClick={() => {copyLink(AUCTION_OVERLAY_OBS_BIG_WEBCAM_URL + localStorage["auctionPassword"]).finally()}}>OBS Overlay [Webcam]</Button>
                            <Button variant="contained" color="secondary" onClick={refreshItems}>Refresh Database</Button>
                            <Button disabled variant="contained" color="secondary" onClick={() => {setNoticeMessage("Not Implemented")}}>Export</Button>
                        </Stack>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
        <Snackbar message={noticeMessage} open={noticeMessage !== ""} autoHideDuration={2000} onClose={() => setNoticeMessage("")} />
    </>
}